.App {
  text-align: center;
  /* this needs to fill the viewport
	position fixed will work on modern mobile devices. */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* add flex-box */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: #fafafa;
  /* min-height: 95vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: #424242;
}

.App-content {
  background-color: #fafafa;

  /* set this to fill the center space */
  flex-basis: calc(100% - 60px);
  /* make it scroll */
  overflow-y: auto;

  /* min-height: 95vh; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 1vmin);
  color: #424242;
}

.App-footer {
  background-color: #fafafa;
  flex-basis: 20px;
  padding: 10px;
  box-sizing: border-box;
  font-size: calc(10px + 0.5vmin);
  color: #919191;
}

.App-link {
  color: #fbc261;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
